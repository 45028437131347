import type { Faro } from "@grafana/faro-web-sdk";

// グローバルのWindow型にfaroプロパティを追加
declare global {
  interface Window {
    faro?: Faro;
  }
}

// faroインスタンスを保持する変数
let faroInstance: Faro | null = null;

// faroインスタンスを設定する関数
export function setFaroInstance(faro: Faro): void {
  faroInstance = faro;
}

// コンポーネントからfaroインスタンスを使用するためのコンポーザブル
export function useFaro(): Faro {
  // ローカルに保存されたインスタンスを使用
  if (!faroInstance) {
    throw new Error("Faro has not been initialized");
  }
  return faroInstance;
}
