import { createApp } from "vue";
import { createHead } from "@unhead/vue";
import { store } from "./store";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Buefy from "buefy";
import "buefy/dist/buefy.min.css";
import App from "./App.vue";
import axios from "axios";
import axiosRetry from "axios-retry";
import VueLazyload from "vue3-lazyload";
import MarqueeText from "vue-marquee-text-component";
import VueMobileDetection from "vue-mobile-detection";
import { createAuth0 } from "@auth0/auth0-vue";
import adsUtils from "./utils/ads";
import copyUtils from "./utils/copy";
import toastUtils from "./utils/toast";
import loadingUtils from "./utils/loading";
import auth0Utils from "./utils/auth0";
import utilsUtils from "./utils/utils";
import modalUtils from "./utils/modal";
import downloadUtils from "./utils/download";
import emailUtils from "./utils/email";
import shareUtils from "./utils/share";
import groupUtils from "./utils/group";
import errorUtils from "./utils/error";

import router from "./router";
import { createRouterScroller } from "vue-router-better-scroller";
import { i18n } from "./i18n-setup";

import { registerSW } from "virtual:pwa-register";
registerSW({ immediate: true });

import { library } from "@fortawesome/fontawesome-svg-core";

// free solid
import { faStore } from "@fortawesome/free-solid-svg-icons/faStore";
import { faUniversity } from "@fortawesome/free-solid-svg-icons/faUniversity";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons/faCreditCard";
import { faHeadset } from "@fortawesome/free-solid-svg-icons/faHeadset";
import { faBolt } from "@fortawesome/free-solid-svg-icons/faBolt";
import { faCogs } from "@fortawesome/free-solid-svg-icons/faCogs";
import { faCoins } from "@fortawesome/free-solid-svg-icons/faCoins";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons/faSyncAlt";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons/faCloudArrowDown";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons/faEyeSlash";
import { faUpload } from "@fortawesome/free-solid-svg-icons/faUpload";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faShieldAlt } from "@fortawesome/free-solid-svg-icons/faShieldAlt";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons/faSignInAlt";
import { faHandHoldingUsd } from "@fortawesome/free-solid-svg-icons/faHandHoldingUsd";
import { faExclamation } from "@fortawesome/free-solid-svg-icons/faExclamation";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons/faUserAlt";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons/faLayerGroup";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { faTools } from "@fortawesome/free-solid-svg-icons/faTools";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons/faAngleLeft";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons/faArrowUp";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons/faUserPlus";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons/faRedoAlt";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import { faListUl } from "@fortawesome/free-solid-svg-icons/faListUl";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons/faAngleUp";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons/faCloudArrowUp";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
import { faInfinity } from "@fortawesome/free-solid-svg-icons/faInfinity";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons/faUserGroup";
import { faCodeMerge } from "@fortawesome/free-solid-svg-icons/faCodeMerge";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons/faPaperPlane";
import { faSkullCrossbones } from "@fortawesome/free-solid-svg-icons/faSkullCrossbones";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle"; // UploadComponent number-input
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons/faEllipsisVertical";
import { faFile } from "@fortawesome/free-solid-svg-icons/faFile";
import { faUpLong } from "@fortawesome/free-solid-svg-icons/faUpLong";
import { faRocket } from "@fortawesome/free-solid-svg-icons/faRocket";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons/faRotateRight";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons/faShareNodes";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faShield } from "@fortawesome/free-solid-svg-icons/faShield";
import { faArrowDownWideShort } from "@fortawesome/free-solid-svg-icons/faArrowDownWideShort";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons/faCalendarDays";
import { faKey } from "@fortawesome/free-solid-svg-icons/faKey";
import { faMicrochip } from "@fortawesome/free-solid-svg-icons/faMicrochip";
import { faLink } from "@fortawesome/free-solid-svg-icons/faLink";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";

// free brand
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faDiscourse } from "@fortawesome/free-brands-svg-icons/faDiscourse";

library.add(
  faStore,
  faUniversity,
  faCreditCard,
  faHeadset,
  faCoins,
  faCogs,
  faBolt,
  faSyncAlt,
  faCloudArrowDown,
  faEye,
  faEyeSlash,
  faChevronDown,
  faClock,
  faShield,
  faLink,
  faRotateRight,
  faUpload,
  faFile,
  faEllipsisVertical,
  faMicrochip,
  faKey,
  faCalendarDays,
  faArrowDownWideShort,
  faExclamationCircle,
  faSkullCrossbones,
  faPaperPlane,
  faCheck,
  faDiscourse,
  faUpLong,
  faCodeMerge,
  faUserGroup,
  faInfinity,
  faMinus,
  faPlus,
  faCloudArrowUp,
  faSignInAlt,
  faChevronRight,
  faShareNodes,
  faRocket,
  faShieldAlt,
  faUserAlt,
  faEnvelope,
  faHandHoldingUsd,
  faExclamation,
  faAngleDown,
  faAngleUp,
  faUsers,
  faLayerGroup,
  faTimesCircle,
  faTools,
  faAngleLeft,
  faAngleRight,
  faArrowUp,
  faUserPlus,
  faRedoAlt,
  faSignOutAlt,
  faListUl,
  faHome,
  faTwitter,
  faExternalLinkAlt,
  faQuestionCircle,
  faTrash,
);

const app = createApp(App);
const head = createHead();

app.mixin(adsUtils);
app.mixin(copyUtils);
app.mixin(toastUtils);
app.mixin(loadingUtils);
app.mixin(auth0Utils);
app.mixin(utilsUtils);
app.mixin(modalUtils);
app.mixin(downloadUtils);
app.mixin(emailUtils);
app.mixin(shareUtils);
app.mixin(groupUtils);
app.mixin(errorUtils);

app.component("font-awesome-icon", FontAwesomeIcon);
app.component("marquee-text", MarqueeText);

import { getWebInstrumentations, initializeFaro } from "@grafana/faro-web-sdk";
import { TracingInstrumentation } from "@grafana/faro-web-tracing";
import { setFaroInstance } from "./helpers/useFaro";
import { LogLevel } from "@grafana/faro-web-sdk";

// 最初にFaroを初期化し、グローバルに利用可能にする
const instrumentationOptions = {
  propagateTraceHeaderCorsUrls: [/localhost/, /api\.hstorage\.io/],
};
const faro = initializeFaro({
  url: import.meta.env.VITE_FARO_URL,
  apiKey: import.meta.env.VITE_FARO_API_KEY,
  instrumentations: [
    ...getWebInstrumentations({
      captureConsole: false,
    }),
    new TracingInstrumentation({ instrumentationOptions }),
  ],
  app: {
    name: "hstorage",
    version: "1.0.0",
    environment: import.meta.env.MODE,
  },
});
// Faroインスタンスを設定
setFaroInstance(faro);

app.use(
  createAuth0({
    domain: "auth.hstorage.io",
    clientId: "gU0Zw3QFQFJlAY8kSiLRINc0yKiYvOpc",
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: "https://easyuploader.auth0.com/api/v2/",
    },
    cacheLocation: "localstorage",
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
  }),
);

app.use(Buefy, {
  defaultIconPack: "fas",
  defaultIconComponent: "font-awesome-icon",
});

app.use(VueMobileDetection);

app.use(VueLazyload, {
  error: "/static/images/loading.svg",
  loading: "/static/images/loading.svg",
});

import ScriptX from "vue-scriptx";

app.use(ScriptX);

import Ads from "vue-google-adsense";

app.use(Ads.Adsense);

app.use(i18n);

router.afterEach((to, from) => {
  const { name, fullPath } = to;
  faro.api.setView({ name: name });
});

app.config.errorHandler = (err) => {
  store.state.isError = true;
  store.state.errorMsg = err;
};

const client = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});
client.interceptors.response.use(
  (response) => {
    return response;
  },
  // 2xxの範囲外にある任意のステータスコードはこの関数をトリガーします
  // レスポンスエラーで何かを実行する
  (error) => {
    if (error.response) {
      if (error.response.status !== 400 && error.response.status !== 404) {
        faro.api.pushLog(["API Error"], {
          context: {
            payload: error.response,
          },
          level: LogLevel.ERROR,
        });
      }
    } else if (error.request) {
      // リクエストが行われましたが、レスポンスは受信されませんでした
      faro.api.pushError(error);
    } else {
      // リクエストの設定中にエラーがトリガーされた何かがありました
      faro.api.pushError(error);
    }
    return Promise.reject(error);
  },
);
axiosRetry(client, {
  retries: 5,
  retryDelay: function (retryCount, error) {
    return 1000;
  },
});
app.config.globalProperties.$axios = client;

app.use(router);
app.use(
  createRouterScroller({
    selectors: {
      window: true,
      body: true,
      ".scrollable": true,
    },
  }),
);
app.use(store);
app.use(head);
app.mount("#app");
